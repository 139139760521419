import React from 'react';
import Img from "gatsby-image"

const Image = function ({image, ...props}) {
    if(image && image.localFile && image.localFile.childImageSharp) {
        return (
            <Img alt={image.alt_text}
                 title={image.title}
                 description={image.description}
                 fluid={image.localFile.childImageSharp.fluid}
                 {...props} />
        );
    }
    return <div></div>;
};

export default Image;
